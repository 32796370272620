<template>
  <div>
    <octo-table
      :action="endpoints.DATATABLES.commissionIndex"
      :fields="fields"
      :filters="filters"
      :name="name"
      :show-id="false"
      enable-export
      enable-export-email
      enable-add
      show-filters
      show-query-input
      :key="`commission-datatable-${datatableKey}`"
      @onResetFilters="filters = {}"
      @onAdd="openEditModal"
    >
      <template v-slot:date="data">
        <div class="small">
          {{ data.row.month}}/{{ data.row.year}}
        </div>
      </template>

      <template v-slot:user="data">
        <div class="small text-uppercase">
        {{ users[data.row.user_id] | optional('lastname') }}
        {{ users[data.row.user_id] | optional('firstname') }}
        </div>
      </template>

      <template v-slot:amount="data">
        <div class="small">{{ data.row.amount | euro }}</div>
      </template>

      <template v-slot:description="data">
        <badge v-if="data.row.reason" :type="data.row.reason | balanceReasonColor" class="text-uppercase">
          {{ $t('balance.reasons.' + data.row.reason) }}
        </badge>
        <div class="small text-uppercase text-nowrap">{{ data.row.description }}</div>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <base-button
            link
            icon
            class="mx-1"
            size="sm"
            v-if="data.row.details && data.row.details.length > 0"
            @click="openModal(data.row)"
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
          <base-button
            link
            icon
            class="mx-1"
            size="sm"
            v-if="data.row.reason === 'custom'"
            @click="openEditModal(data.row)"
          >
            <octo-icon icon="edit"/>
          </base-button>
          <base-button
            link
            icon
            class="mx-1"
            size="sm"
            v-if="data.row.reason === 'custom'"
            @click="deleteCommission(data.row.id)"
            confirm-required
            :confirm-text="$t('confirm.delete_custom_commission')"
          >
            <octo-icon icon="wrong"/>
          </base-button>
        </div>
      </template>

      <template slot="custom-filters">
        <div class="col-12 col-md-4">
          <base-input :label="$t('common.select_month')" class="flex-grow-1">
            <el-date-picker
              type="month"
              value-format="yyyy-MM"
              format="MM/yyyy"
              v-model="filters.date"
              :placeholder="$t('common.select_month')"
            />
          </base-input>
        </div>
        <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.user')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.userIds"
          >
            <el-option
              v-for="user in users"
              class="select-default text-uppercase"
              :value="user.id"
              :key="user.id"
              :label="`${$options.filters.optional(user, 'firstname')} ` +
                      `${$options.filters.optional(user, 'lastname')}`"
            >
            </el-option>
          </el-select>
        </base-input>
        </div>
      </template>

    </octo-table>
    <modal centered bodyClasses="px-2" :show.sync="showEditModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ currentCommission.id ? $t('common.edit') : $t('common.add') }}
      </h5>
      <commission-form :key="`form-${modalKey}`" :commission="currentCommission" ref="commissionForm"/>
      <template slot="footer">
        <base-button link @click="saveCommission">
          <span class="text-capitalize">{{ $t('common.save') }}</span>
        </base-button>
      </template>
    </modal>

    <modal centered bodyClasses="px-2" modalClasses="modal-xl" :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.details') }}</h5>
      <commission-details :key="`details-${modalKey}`" :details="currentCommission.details || []"/>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Select, Option, DatePicker} from "element-ui";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import Badge from "@/components/Badge";
import CommissionForm from "@/pages/Balances/components/CommissionForm";
import Commission from "@/models/commission";
import CommissionDetails from "@/pages/Balances/components/CommissionDetails";

export default {
  name: "CommissionIndexDatatable",
  components: {
    CommissionDetails,
    CommissionForm,
    Modal,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      name: 'CommissionIndexDatatable',
      endpoints: endpoints,
      showModal: false,
      showEditModal: false,
      modalKey: 0,
      datatableKey: 0,
      currentCommission: _.cloneDeep(Commission),
      filters: {},
      fields: [
        {prop: 'date', label: 'date', slot: true, align: 'center', fixed: true, width: 100},
        {prop: 'user', label: 'user', slot: true, align: 'left', fixed: true, width: 200},
        {prop: 'description', label: 'description', slot: true, align: 'left'},
        {prop: 'amount', label: 'amount', slot: true, align: 'right', width: 100},
        {prop: 'actions', label: 'actions', width: 100, slot: true},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      getFilters: 'common/datatableFilters',
    }),
  },
  methods: {
    openEditModal(commission) {
      this.currentCommission = this.$_.cloneDeep(commission || Commission);
      this.modalKey++;
      this.showEditModal = true;
    },

    openModal(commission) {
      this.currentCommission = this.$_.cloneDeep(commission);
      this.modalKey++;
      this.showModal = true;
    },

    deleteCommission(commissionId) {
      this.$fullLoading.show();

      this.$api.delete(endpoints.COMMISSIONS_DELETE.replace('{id}', commissionId))
        .then(() => {
          this.datatableKey++;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.delete_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    saveCommission: async function () {
      try {
        const commission = await this.$refs.commissionForm.validate();

        this.$fullLoading.show();

        if (commission?.id) {
          await this.$api.put(endpoints.COMMISSIONS_UPDATE.replace('{id}', commission.id), commission);
        } else {
          await this.$api.post(endpoints.COMMISSIONS_STORE, commission);
        }

        this.datatableKey++;
        this.showEditModal = false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
