const Commission = {
  id: null,
  user_id: null,
  month: '',
  year: '',
  reason: '',
  description: '',
  amount: null,
  details: [],
};

export default Commission
