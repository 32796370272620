<template>
  <commission-index-datatable/>
</template>

<script>
import CommissionIndexDatatable from "@/pages/Balances/datatables/CommissionIndexDatatable";
export default {
  name: "IndexCommissionsPage",
  components: {CommissionIndexDatatable}
}
</script>

<style scoped>

</style>
