<template>
  <el-table stripe :data="details" max-height="500">
    <el-table-column align="left" :label="$t('fields.amount')" width="150">
      <div slot-scope="{ row }">{{ row.amount | euro }}</div>
    </el-table-column>
    <el-table-column align="left" :label="$t('fields.description')" min-width="200">
      <div slot-scope="{ row }" class="white-space-pre">{{ row.description }}</div>
    </el-table-column>
  </el-table>
</template>

<script>
import {Table, TableColumn} from "element-ui";

export default {
  name: "CommissionDetails",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    details: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
