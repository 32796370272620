<template>
    <ValidationObserver ref="commissionForm">
      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('fields.reason')" :value="$t('balance.reasons.custom')"/>
        <list-group-item-component :label="$t('fields.month')" :label-width="labelWidth">
          <template slot="value">
            <ValidationProvider
              class="flex-grow-1"
              name="month"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                class="mb-0"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              >
                <el-date-picker
                  type="month"
                  v-model="dateSelected"
                  value-format="yyyy-MM"
                  format="MM/yyyy"
                  :placeholder="$t('common.select_month')"
                />
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.amount')" :label-width="labelWidth">
          <template slot="value">
            <ValidationProvider
              class="flex-grow-1"
              name="amount"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                class="mb-0"
                type="number"
                v-model="commissionLocal.amount"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              />
            </ValidationProvider>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.user')" :label-width="labelWidth">
          <template slot="value">
            <ValidationProvider
              class="flex-grow-1"
              name="user"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                class="mb-0"
                :error="errors[0]"
                :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
              >
                <el-select
                  v-model="commissionLocal.user_id"
                  class="select-default text-uppercase"
                  :placeholder="$t('fields.choose')"
                >
                  <el-option
                    v-for="option in users"
                    class="select-default text-uppercase"
                    :key="option.id"
                    :value="option.id"
                    :label="$options.filters.optional(users[option.id], 'lastname') + ' ' +
                        $options.filters.optional(users[option.id], 'firstname')"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.description')" :label-width="labelWidth">
          <template slot="value">
            <base-text-area v-model="commissionLocal.description" class="flex-grow-1"/>
          </template>
        </list-group-item-component>
      </ul>
    </ValidationObserver>
</template>

<script>
  import { Select, Option, DatePicker } from 'element-ui';
  import ListGroupItemComponent from "@/components/ListGroupItemComponent";
  import Commission from "@/models/commission";
  import BaseTextArea from "@/components/Inputs/BaseTextArea";
  import {mapGetters} from "vuex";

  export default {
    name: "CommissionForm",
    data() {
      return {
        commissionLocal: null,
        dateSelected: '',
        labelWidth: 140
      }
    },
    components: {
      BaseTextArea,
      ListGroupItemComponent,
      [Option.name]: Option,
      [Select.name]: Select,
      [DatePicker.name]: DatePicker
    },
    watch: {
      dateSelected(newDate, oldDate) {
        this.commissionLocal.month = newDate?.split('-')[1] || '';
        this.commissionLocal.year = newDate?.split('-')[0] || '';
      }
    },
    props: {
      commission: {
        type: Object,
        default: () => _.cloneDeep(Commission)
      }
    },
    computed: {
      ...mapGetters({
        users: 'common/users'
      })
    },
    beforeMount() {
      this.commissionLocal = this.$_.cloneDeep(this.commission);
      this.dateSelected = this.commissionLocal.id
        ? this.$moment({year: this.commissionLocal.year, month: this.commissionLocal.month - 1}).format('YYYY-MM')
        : '';
    },
    methods: {
      validate() {
        return new Promise((resolve, reject) => {
          this.$refs.commissionForm.validate()
            .then((success) => {
              if (!success) {
                reject();
              } else {
                resolve(this.commissionLocal);
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    },
  }
</script>

<style scoped>

</style>
